// Generated by Framer (4d5c76d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["x9ceVbk6y"];

const serializationHash = "framer-X2PT8"

const variantClassNames = {x9ceVbk6y: "framer-v-uxq40s"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, h2mT8ot8N: tap ?? props.h2mT8ot8N} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, h2mT8ot8N, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "x9ceVbk6y", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapr4zp0u = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (h2mT8ot8N) {
const res = await h2mT8ot8N(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-uxq40s", className, classNames)} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"x9ceVbk6y"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapr4zp0u} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-poeta1"} layoutDependency={layoutDependency} layoutId={"NALFnEdc6"} style={{backgroundColor: "rgb(0, 0, 0)", rotate: -45}}/><motion.div className={"framer-omn47s"} layoutDependency={layoutDependency} layoutId={"jH9FizcJL"} style={{backgroundColor: "rgb(0, 0, 0)", rotate: 45}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-X2PT8[data-border=\"true\"]::after, .framer-X2PT8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-X2PT8.framer-1uaxcqy, .framer-X2PT8 .framer-1uaxcqy { display: block; }", ".framer-X2PT8.framer-uxq40s { cursor: pointer; height: 40px; overflow: hidden; position: relative; width: 40px; }", ".framer-X2PT8 .framer-poeta1 { flex: none; height: 2px; left: 7px; overflow: hidden; position: absolute; right: 9px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-X2PT8 .framer-omn47s { flex: none; height: 2px; left: calc(47.50000000000002% - 24px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 2px / 2); width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"h2mT8ot8N":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerY_bYSshs5: React.ComponentType<Props> = withCSS(Component, css, "framer-X2PT8") as typeof Component;
export default FramerY_bYSshs5;

FramerY_bYSshs5.displayName = "Close overlay Button";

FramerY_bYSshs5.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerY_bYSshs5, {h2mT8ot8N: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerY_bYSshs5, [])